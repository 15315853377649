<template>
  <sm-editable-item
    v-model="form"
    :controllerName="controllerName"
    :breadcrumbs="breadcrumbs"
    :pageHeader="pageHeader"
    :fields="fields"
    @save="onSave('MailBoxes')"
    @cancel="onCancel('MailBoxes')"
  />
</template>

<script>
// mixins
import editableListItemCreate from '@/mixins/editableListItemCreate.js';
// vuex
import { mapState, mapActions } from 'vuex';
// components
import SmEditableItem from '@/components/views/SmEditableItem.vue';

export default {
  name: 'MailBoxesCreate',

  components: {
    SmEditableItem,
  },

  mixins: [editableListItemCreate],

  data() {
    return {
      controllerName: 'MailBoxes',
      pageHeader: 'Создание почтового ящика',
      breadcrumbs: [
        {
          text: 'Администрирование',
          route: { name: 'AdministrationMain' },
        },
        {
          text: 'Почтовые ящики',
          route: { name: 'MailBoxes' },
        },
        {
          text: 'Создание почтового ящика',
        },
      ],
      initialForm: {
        name: null,
        server: null,
        userName: null,
        password: null,
        port: null,
        useSsl: false,
        disabled: false,
        accountId: null,
        messageGroups: [],
      },
      form: null,
    };
  },

  computed: {
    ...mapState({
      accounts: (state) => state.common.accounts.data,
    }),

    fields() {
      return [
        {
          tab: 'Основные',
          form: [
            {
              type: 'text',
              key: 'name',
              label: 'Название почт. ящика',
            },
            {
              type: 'text',
              key: 'server',
              label: 'Сервер',
            },
            {
              type: 'text',
              key: 'userName',
              label: 'Имя пользователя',
            },
            {
              type: 'select',
              key: 'accountId',
              label: 'ID пользователя',
              list: this.accounts
            },
            {
              type: 'text',
              key: 'password',
              label: 'Пароль пользователя',
            },
            {
              type: 'text',
              key: 'port',
              label: 'Номер порта',
            },
            {
              type: 'checkbox',
              key: 'disabled',
              label: 'Ящик неактивен',
            },
            {
              type: 'checkbox',
              key: 'useSsl',
              label: 'Использовует SSL',
            },
          ],
        },
        {
          tab: 'Почтовые группы',
          table: {
            headers: [
              {
                text: 'Название группы',
                order: 'name',
                alias: 'name',
              },
              {
                alias: 'actions',
              },
            ],
            items: this.form.messageGroups,
            key: 'messageGroups',
            modalFields: [
              {
                type: 'text',
                key: 'name',
                label: 'Название группы',
              },
            ],
            showEditButton: true,
            showDeleteButton: true,
            showCreateButton: true,
          },
        },
      ];
    },
  },
  
  created() {
    this.isLoadingPage = true;
    const accounts = this.getCommonList({ name: 'Accounts' });

    Promise.all([
      accounts,
    ]).then(() => {
      this.isLoadingPage = false;
    });
  },

  methods: {
    ...mapActions({
      createItem: 'editableList/createItem',
      getCommonList: 'common/getCommonList',
    }),
  },
};
</script>
